export function getCurrentAmendmentId<T extends boolean = true>(
  shouldThrow?: T,
): T extends false ? string | undefined : string
export function getCurrentAmendmentId(shouldThrow = true) {
  const route = useRoute(
    'division-companyId-lease-leaseDocumentId-amendments-amendmentId',
  )
  const leaseDocumentId = route.params.amendmentId as string | undefined

  if (shouldThrow && !leaseDocumentId) {
    throw new Error('Amendment not available')
  }

  return leaseDocumentId
}
