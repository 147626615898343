import { useMutation, useQueryClient } from '@tanstack/vue-query'
import type {
  ICreateLeaseVariationRequest,
  ILeaseVariation,
  IUpdateLeaseVariationRequest,
} from '@register'

export type ILeaseVariationMutationParams = {
  body: ICreateLeaseVariationRequest | IUpdateLeaseVariationRequest
  variationId?: string
}
export const useApiLeaseVariationsMutation = (
  companyId: string,
  leaseId: string,
) => {
  // This is the ID of the amendment that is currently being edited
  // We get it from route params
  // Don't confuse it with the `variationId` of the `mutationFn`,
  // which can be different from the `currentAmendmentId`
  const currentAmendmentId = getCurrentAmendmentId(false)
  const queryClient = useQueryClient()

  let controller: AbortController | null = null
  let signal: AbortSignal | null = null

  return useMutation({
    mutationFn: ({ body, variationId }: ILeaseVariationMutationParams) => {
      // If a previous request is in progress, abort it
      if (controller) {
        controller.abort()
      }

      // Create a new AbortController and signal for the upcoming request
      controller = new AbortController()
      signal = controller.signal

      return fetchAPI<ILeaseVariation | ILeaseVariation[]>(
        createSaveURL(
          `/companies/${companyId}/leaseRegister/leases/${leaseId}/variations`,
          variationId,
        ),
        {
          method: variationId ? 'PUT' : 'POST',
          body,
          signal,
        },
      )
    },
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries({
        queryKey: ['companies', companyId, 'leases', leaseId],
        exact: true,
      })

      if (currentAmendmentId) {
        await queryClient.invalidateQueries({
          queryKey: [
            'companies',
            companyId,
            'leases',
            leaseId,
            'documents',
            'variations',
            currentAmendmentId,
            'related',
          ],
        })
      }

      queryClient.invalidateQueries({
        queryKey: [
          'companies',
          companyId,
          'leases',
          leaseId,
          'documents',
          'variations',
        ],
        exact: true,
      })

      queryClient.invalidateQueries({
        queryKey: ['getLeaseDocumentId', companyId, leaseId],
        exact: true,
      })
    },
  })
}
