import { joinURL } from 'ufo'

const isNonEmptyURL = (url: string | undefined): url is string =>
  !!url && url !== '/'

export const createSaveURL = (base: string, id: string | undefined): string => {
  const url = base || ''
  if (isNonEmptyURL(id)) return joinURL(url, id)
  return url
}
